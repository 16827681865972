// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import Vue from 'vue'

// https://webpack.js.org/guides/dependency-management/#require-context
const requireDirective = require.context(
  // Look for files in the current directory
  '.',
  // Do not look in subdirectories
  true,
  // Only include "_base-" prefixed .vue files
  /index\.js$/
)

// For each matching file name...
requireDirective.keys()
  .forEach((filePath) => {
    // Get the directive config
    const directiveConfig = requireDirective(filePath)
    // Get the PascalCase version of the directive name
    const filePathArray = filePath.split('/')
    const directiveName = filePathArray[filePathArray.length - 2]
    // Globally register the directive
    Vue.directive(directiveName, directiveConfig.default || directiveConfig)
  })
