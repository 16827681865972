<template>
  <span></span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ZWebsocket',
  data() {
    return {
      // websocket 对象
      websocket: null,
      // 定时器对象
      intervalObj: null,
      // 当前重试次数
      currentRetryNumber: 1
    }
  },
  props: {
    // 会话类型
    sessionType: {
      type: String,
      default: null
    },
    // 断开是否重试
    retry: {
      type: Boolean,
      default: false
    },
    // 重试次数
    retryCount: {
      type: Number,
      default: 10
    }
  },
  computed: {
    // 用户信息
    ...mapGetters('user', ['userInfo'])
  },
  created() {
    // 初始化 Websocket
    this.initWebsocket()
    // 心跳检查
    this.intervalObj = setInterval(this.checkState, 3000)
  },
  methods: {
    /**
     * 初始化 Websocket
     */
    initWebsocket() {
      // 如果当前浏览器不支持 Websocket，则打印日志，然后返回
      if (typeof WebSocket === 'undefined') {
        console.error('Websocket 初始化失败（当前浏览器不支持 WebSocket）！')
        return
      }
      // 如果已连接或正在连接，则返回
      if (this.websocket && [0, 1].includes(this.websocket.readyState)) {
        console.error('Websocket 已连接或正在连接！')
        return
      }
      // 如果用户信息无效，则返回
      if (!this.userInfo || !this.userInfo.id) {
        console.error('Websocket 初始化失败（无效的用户信息）！')
        return
      }
      // 如果会话类型无效，则返回
      if (!this.sessionType) {
        console.error('Websocket 初始化失败（无效的会话类型）！')
        return
      }
      this.websocket = new WebSocket(`${window.location.protocol === 'http:' ? 'ws' : 'wss'}://${window.location.origin.substring(window.location.origin.indexOf('//') + 2)}/api/websocket/${this.userInfo.id}/${this.sessionType}`)
      // 监听消息 连接
      this.websocket.onopen = (evt) => {
        this.websocket.send('来自前端的反馈：建立连接成功！')
        this.$emit('onopen', evt)
      }
      // 监听消息 接收
      this.websocket.onmessage = (evt) => {
        // 首次连接会返回状态
        if (evt.data !== 'SUCCESS') {
          this.$emit('onmessage', evt)
        }
      }
      // 监听消息 关闭
      this.websocket.onclose = (evt) => {
        console.info('Websocket 断开连接成功！')
        this.$emit('onclose', evt)
      }
      // 监听消息 异常
      this.websocket.onerror = (evt) => {
        console.error('WebSocket error observed:', evt)
        this.$emit('onerror', evt)
      }
    },
    /**
     * 状态检查
     */
    checkState() {
      // 如果不需要断开重试
      if (!this.retry) {
        //  关闭定时器
        clearInterval(this.intervalObj)
        return
      }
      if (this.currentRetryNumber > this.retryCount) {
        //  关闭定时器
        clearInterval(this.intervalObj)
        return
      }
      // 如果需要断开重试
      if (!this.websocket || this.websocket.readyState === 3) {
        console.log('当前重试第' + this.currentRetryNumber + '次，共需重试' + this.retryCount + '次')
        this.initWebsocket()
      }
      this.currentRetryNumber++
    }
  },
  destroyed() {
    //  关闭定时器
    clearInterval(this.intervalObj)
  }
}
</script>
