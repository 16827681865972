import Cookies from 'js-cookie'

const TOKEN_INFO_KEY = 'didu-token-info'

const auth = {
  setTokenInfo2Cookie(tokenInfo) {
    Cookies.set(TOKEN_INFO_KEY, JSON.stringify(tokenInfo), { expires: 1, sameSite: 'Lax', path: '/' })
  },
  getTokenFromCookie() {
    const tokenInfoObject = Cookies.get(TOKEN_INFO_KEY)
    if (tokenInfoObject) {
      const tokenInfo = JSON.parse(tokenInfoObject)
      return tokenInfo.access_token
    }
    return null
  },
  clearAuth() {
    Cookies.remove(TOKEN_INFO_KEY)
  }
}

export default auth
