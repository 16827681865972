import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const files = require.context('.', true, /\.js$/)

const modules = {}

files.keys().forEach(file => {
  if (file != './index.js') {
    const module = require(`${file}`)
    modules[module.default.name] = module.default.store
  }
})

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules
})
