import { passwordLogin, userLogout } from '@/services/login/index'
import auth from '@/utils/auth.util'
import Vue from 'vue'

const login = {
  namespaced: true,
  state: {
    // authInfo的内容如下👇🏻
    // access_token "eyJhbGci"
    // token_type "bearer"
    // refresh_token "eyJhbGc"
    // expires_in 86399
    // scope "all"
    // mobilePhone "t11"
    // id "1597594343017943040"
    // account "t11"
    // jti "281433a5-ca2f-4593-8d8a-a4a206d18a5b"
    authInfo: {}
  },
  mutations: {
    setAuthInfo(state, authResult) {
      state.authInfo = { ...authResult }
    },
    clearAuthInfo(state) {
      state.authInfo = {}
    }
  },
  actions: {
    accountPasswordLogin({ dispatch, commit }, loginInfo) {
      return new Promise((resolve, reject) => {
        passwordLogin(loginInfo)
          .then(async authResult => {
            if (authResult && authResult.data) {
              auth.setTokenInfo2Cookie(authResult.data)
              commit('setAuthInfo', authResult.data)
              // authResult.data.id是accountId，通过此id查询用户信息
              await dispatch('callback', authResult.data.id)
              resolve(authResult.data)
            }
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async callback({ dispatch }, accountId) {
      // load user info
      await dispatch('user/loadUserInfo', accountId, { root: true })
      // load user authorization
      await dispatch('authorization/loadAuthorization', null, { root: true })
    },
    logout({ dispatch }) {
      return new Promise((resolve, reject) => {
        userLogout().then(async () => {
          await dispatch('clearLocalAuth')
          resolve()
        }).catch(reject)
      })
    },
    clearLocalAuth({ commit }) {
      return new Promise((resolve) => {
        // 移除cookie
        auth.clearAuth()
        // 清空store中的用户认证信息
        commit('clearAuthInfo')
        // 清空授权信息
        commit('authorization/clearAuthorization', null, { root: true })
        // 清空本地存储中的用户信息
        Vue.prototype.$lowdb('user').set('user', {}).write()
        resolve()
      })
    }
  }
}

export default {
  name: 'login',
  store: login
}
