import Vue from 'vue'
import { loadDataAuthorizationService, loadFunctionAuthorizationService } from '@/services/login-callback'

const AuthorizationEnum = {
  APPLICATION: 'APPLICATION',
  FUNCTION: 'FUNCTION',
  PERMISSION: 'PERMISSION'
}

const authorization = {
  namespaced: true,
  state: () => ({
    authorization: {},
    selectedApplication: null,
    selectedFunction: null,
    breadcrumb: []
  }),
  getters: {
    authorizationMap: (state) => {
      return state.authorization
    },
    applicationList: (state) => {
      return state.authorization[AuthorizationEnum.APPLICATION]
    },
    functionList: (state) => {
      return state.authorization[AuthorizationEnum.FUNCTION]
    },
    permissionList: (state) => {
      return state.authorization[AuthorizationEnum.PERMISSION]
    }
  },
  mutations: {
    setAuthorization(state, payload) {
      state.authorization = { ...payload }
    },
    clearAuthorization(state) {
      state.authorization = {}
    },
    setSelectedApplication(state, payload) {
      state.selectedApplication = { ...payload }
    },
    setSelectedFunction(state, payload) {
      state.selectedFunction = { ...payload }
    },
    setBreadcrumb(state, payload) {
      state.breadcrumb = { ...payload }
    }
  },
  actions: {
    /**
     * 从后端加载权限数据到程序，并缓存到内存中
     * @param commit
     */
    async loadAuthorization({ commit }) {
      const { data: authorization } = await loadFunctionAuthorizationService()
      const applicationList = authorization[AuthorizationEnum.APPLICATION]
      // init dashboard
      applicationList.forEach(v => {
        const dashboard = {
          id: v.id + '-dashboard',
          functionName: '工作台',
          functionType: 'FUNCTION',
          icon: 'el-icon-s-home',
          functionUrl: '/dashboard',
          applicationId: v.id
        }
        authorization[AuthorizationEnum.FUNCTION].unshift(dashboard)
      })
      // commit
      commit('setAuthorization', authorization)
      // cache
      Vue.prototype.$lowdb('user').set('user.authorization', authorization).write()
      // load data authorization
      await loadDataAuthorizationService()
    },
    /**
     * 从内存中加载权限数据到程序
     * @param commit
     */
    resetAuthorization({ commit }) {
      const authorization = Vue.prototype.$lowdb('user').get('user.authorization').value() || {}
      // commit
      commit('setAuthorization', authorization)
    },
    /**
     * 通过功能编码查找功能ID
     * @param getters
     * @param code
     * @returns {string|*}
     */
    getFunctionIdByFunctionCode({ getters }, code = '') {
      const { functionList } = getters
      const funcObj = functionList.find(v => v.functionCode === code)
      if (funcObj) {
        return funcObj.id
      }
      console.error('未找到有效的function，code is：' + code)
      return 'function not found'
    },
    /**
     * 刷新当前选中的应用
     * @param getters
     * @param commit
     * @param applicationId
     */
    async refreshSelectedApplication({ getters, commit, dispatch }, applicationId = '') {
      const { applicationList } = getters
      // find application
      const selectedApplication = applicationList.find(v => v.id === applicationId)
      // commit
      commit('setSelectedApplication', selectedApplication)
    },
    /**
     * 刷新当前选中的功能
     * @param getters
     * @param commit
     * @param functionId
     */
    async refreshSelectedFunction({ getters, commit, dispatch }, functionId = '') {
      const { functionList } = getters
      // find function
      const selectedFunction = functionList.find(v => v.id === functionId)
      if (selectedFunction) {
        // commit
        commit('setSelectedFunction', selectedFunction)
        // refresh selected application
        await dispatch('refreshSelectedApplication', selectedFunction.applicationId)
        // refresh bread
        await dispatch('refreshBreadcrumb', functionId)
      }
    },
    /**
     * 更新面包屑
     * @param getters
     * @param commit
     * @param functionId
     */
    refreshBreadcrumb({ getters, commit }, functionId = '') {
      let breadcrumb = []
      const { functionList } = getters
      const selectedFunction = functionList.find(v => v.id === functionId)
      if (selectedFunction) {
        const parentId = selectedFunction.parentId
        if (parentId) {
          const parentFunction = functionList.find(v => v.id === parentId)
          breadcrumb = [parentFunction.functionName]
        }
        breadcrumb = [...breadcrumb, selectedFunction.functionName]
      }
      // commit
      commit('setBreadcrumb', breadcrumb)
    }
  }
}

export default {
  name: 'authorization',
  store: authorization
}
