<template>
  <div class="d-panel-table-container">
    <div class="d-panel-table-header">
      <div class="d-panel-table-header-des">
        {{ des }}
      </div>
      <div class="d-panel-table-header-option">
        <div class="d-panel-table-header-search">
          <slot name="table-header-search"></slot>
        </div>
        <div class="d-panel-table-header-btn">
          <slot name="table-header-btn"></slot>
        </div>
      </div>
    </div>
    <div class="d-panel-table-area">
      <slot name="table"></slot>
    </div>
    <div class="d-panel-table-pagination">
      <slot name="table-pagination"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'd-panel-table',
  props: {
    des: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.d-panel-table-container {
  display: flex;
  flex-direction: column;
  .d-panel-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    .d-panel-table-header-option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .d-panel-table-header-search {
        width: 500px;
      }
    }
  }
  .d-panel-table-pagination {
    position: absolute;
    right: 20px;
    bottom: 70px;
  }
}
</style>
