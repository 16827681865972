<template>
  <el-select :value="valueTitle" :disabled="disabled" :clearable="clearable" @clear="clearHandle">
    <el-option :value="valueTitle" :label="valueTitle">
      <el-tree id="tree-option"
               ref="selectTree"
               :accordion="accordion"
               :data="options"
               :props="props"
               :node-key="props.value"
               :default-expanded-keys="defaultExpandedKey"
               @node-click="handleNodeClick">
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'd-select-tree',
  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => {
        return {
          // ID字段名
          value: 'id',
          // 显示名称
          label: 'title',
          // 子级字段名
          children: 'children'
        }
      }
    },
    /* 选项列表数据(树形结构的对象数组) */
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    /* 初始值 */
    value: {
      type: String,
      default: () => {
        return null
      }
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      // 初始值
      valueId: this.value,
      valueTitle: '',
      defaultExpandedKey: []
    }
  },
  mounted() {
    this.initHandle()
  },
  methods: {
    // 初始化值
    initHandle() {
      // alert('this.valueId=' + this.valueId)
      const that = this
      // 这里要延迟执行，否则有BUG
      setTimeout(function () {
        if (that.valueId) {
          // 初始化显示
          that.valueTitle = that.$refs.selectTree.getNode(that.valueId).data[that.props.label]
          // 设置默认选中
          that.$refs.selectTree.setCurrentKey(that.valueId)
          // 设置默认展开
          that.defaultExpandedKey = [that.valueId]
        } else {
          // 初始化显示
          that.valueTitle = null
          // 设置默认选中
          that.$refs.selectTree.setCurrentKey(null)
        }
      }, 200)
      this.$nextTick(() => {
        const scrollWrap = document.querySelectorAll('.el-scrollbar .el-select-dropdown__wrap')[0]
        const scrollBar = document.querySelectorAll('.el-scrollbar .el-scrollbar__bar')
        scrollWrap.style.cssText = 'margin: 0px; max-height: none; overflow: hidden;'
        // eslint-disable-next-line no-return-assign
        scrollBar.forEach(ele => ele.style.width = 0)
      })
    },
    // 切换选项
    handleNodeClick(node) {
      this.valueTitle = node[this.props.label]
      this.valueId = node[this.props.value]
      // this.$emit('getValue',this.valueId)
      this.$emit('input', this.valueId)
      this.defaultExpandedKey = []
    },
    // 清除选中
    clearHandle() {
      this.valueTitle = ''
      this.valueId = null
      this.defaultExpandedKey = []
      this.clearSelected()
      // this.$emit('getValue',null)
      this.$emit('input', null)
    },
    /* 清空选中样式 */
    clearSelected() {
      const allNode = document.querySelectorAll('#tree-option .el-tree-node')
      allNode.forEach((element) => element.classList.remove('is-current'))
    }
  },
  watch: {
    value() {
      this.valueId = this.value
      this.initHandle()
    }
  }
}
</script>

<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item{
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected{
  font-weight: normal;
}
ul li >>>.el-tree .el-tree-node__content{
  height:auto;
  padding: 0 20px;
}
.el-tree-node__label{
  font-weight: normal;
}
.el-tree >>>.is-current .el-tree-node__label{
  color: #409EFF;
  font-weight: 700;
}
.el-tree >>>.is-current .el-tree-node__children .el-tree-node__label{
  color:#606266;
  font-weight: normal;
}
</style>
