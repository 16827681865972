export default {
  appearance: {
    // 登录页客户公司名称
    customCompanyName: '燕牌线缆',
    // 登录页联系人电话
    contactMobilePhone: '135-2734-6216,186-8175-9737',
    // 登录页联系人邮箱
    contactMail: 'dusongsy@outlook.com',
    // 登录页开发者名称
    developCompanyName: 'DiDu Group'
  }
}
