<template>
  <el-container
    class="d-panel-main"
    :class="mode === 'dialog' ? 'd-panel-main-dialog' : 'd-panel-main-drawer'"
  >
    <el-main>
      <slot></slot>
    </el-main>
    <el-footer
      class="d-panel-footer"
      :class="
        mode === 'dialog' ? '' : 'd-panel-drawer-footer'
      "
    >
      <slot name="footer"></slot>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: 'd-panel',
  props: {
    // dialog or drawer
    mode: {
      type: String,
      default: 'dialog'
    }
  }
}
</script>

<style scoped>
.d-panel-main {
  border-top: #eee 1px solid;
}

.d-panel-main-dialog {
  height: 60vh;
}

.d-panel-main-drawer {
  margin-bottom: 40px;
}

.d-panel-drawer-footer {
  position: absolute;
  bottom: 0;
  right: 0;
}

.d-panel-footer {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
}
</style>
