/**
 * 获取url get参数
 * @return object get参数对象
 */
export function getQueryParam() {
  if (!window.location || !window.location.search) {
    return ''
  }
  const ret = window.location.search.split('?')[1].split('&').map(v => ({ [v.split('=')[0]]: v.split('=')[1] }))
  return Object.assign(...ret)
}

/**
 * 获取url get参数
 * @return object get参数对象
 */
export function getUrlParam(url) {
  if (!url || url.indexOf('?') === -1) {
    return {}
  }
  const ret = url.split('?')[1].split('&')
    .map(v =>
      ({ [v.split('=')[0]]: v.split('=')[1] })
    )
  return Object.assign(...ret)
}
