import Vue from 'vue'
import { loadCurrentErpUserInfo } from '@/services/organization-user-right/erp-user'

const user = {
  namespaced: true,
  state: () => ({
    user: {}
  }),
  getters: {
    userInfo: (state) => {
      return state.user
    },
    userId: (state) => {
      return state.user.id
    },
    defaultStation: (state) => {
      return state.user.userStations.find(v => v.defaulted)
    }
  },
  mutations: {
    setUserInfo(state, payload) {
      state.user = { ...payload }
    }
  },
  actions: {
    /**
     * 从后端加载用户数据到程序，并缓存到内存中
     * @param commit
     */
    async loadUserInfo({ commit }, accountId) {
      const result = await loadCurrentErpUserInfo(accountId)
      // commit
      commit('setUserInfo', result.data)
      // cache
      Vue.prototype.$lowdb('user').set('user.info', result.data).write()
    },
    /**
     * 从内存中加载用户数据到程序
     * @param commit
     */
    resetUserInfo({ commit }) {
      const user = Vue.prototype.$lowdb('user').get('user.info').value() || {}
      // commit
      commit('setUserInfo', user)
    }
  }
}

export default {
  name: 'user',
  store: user
}
