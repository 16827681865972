<template>
  <div class="account-login-container">
    <el-form label-position="top" :model="accountInfo">
      <el-form-item class="account-login-form-label" label="账号">
        <el-input v-model="accountInfo.username" name="username" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="登录密码">
        <el-input v-model="accountInfo.password" @keyup.enter.native="login" name="password" placeholder="请输入登录密码" show-password></el-input>
      </el-form-item>
      <el-form-item class="login-button-item">
        <el-button class="login-button" type="primary" :loading="loginPending" @click="login">登录</el-button>
      </el-form-item>
    </el-form>
    <div class="login-form-more">
      <!-- <el-button type="text">忘记密码？</el-button> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import md5 from 'js-md5'

export default {
  name: 'AccountLogin',
  data() {
    return {
      accountInfo: {
        username: null,
        password: null
      },
      loginPending: false
    }
  },
  methods: {
    ...mapActions('login', ['accountPasswordLogin']),
    async login() {
      if (!this.validateLoginInfo()) return
      this.loginPending = true
      const isAuthenticated = await this.accountPasswordLogin({
        username: this.accountInfo.username,
        // 原密码进行md5加密，数据库中存储的是md5加密之后，再进行加密的密码
        password: md5(this.accountInfo.password)
      }).catch((err) => {
        if (err && err.response && err.response.data.errmsg) {
          this.$message.error(err.response.data.errmsg)
        } else {
          this.$message.error('登录异常，请联系管理员！')
        }
      })
      this.loginPending = false
      if (isAuthenticated) {
        this.$router.replace({ path: '/' })
      }
    },
    validateLoginInfo() {
      if (!this.accountInfo.username) {
        this.$message.warning('请输入账号！')
        return false
      }

      if (!this.accountInfo.password) {
        this.$message.warning('请输入密码！')
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.account-login-container {
  width: 100%;
  height: 100%;

  ::v-deep .el-form-item__label {
    padding-bottom: 0px;
    font-size: 16px;
  }

  .login-button {
    width: 100%;
    margin-top: 18px;
  }

.login-button-item {
  margin-bottom: 0px;
}

  .login-form-more {
    height: 36px;
    display: flex;
    align-items: center;

    .el-button.el-button--text {
      font-weight: 400;
      font-size: 12px;
    }
  }
}
</style>
