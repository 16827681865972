const menu = {
  namespaced: true,
  state() {
    return {
      systemData: [
        {
          id: '1',
          systemName: '基础平台',
          icon: 'basic.svg',
          functions: [
            {
              id: '0',
              functionName: 'Dashboard',
              isGroup: false,
              icon: 'el-icon-s-home'
            },
            {
              id: '15',
              functionName: '组织用户权限',
              isGroup: true,
              icon: 'el-icon-user-solid',
              children: [
                {
                  id: '16',
                  functionName: '组织架构',
                  isGroup: false,
                  path: '/organization-user-right/organization/index'
                },
                {
                  id: '17',
                  functionName: '用户管理',
                  isGroup: false,
                  path: '/organization-user-right/erp-user/index'
                },
                {
                  id: '18',
                  functionName: '角色管理',
                  isGroup: false,
                  path: '/organization-user-right/role/index'
                },
                { id: '19', functionName: '权限管理', isGroup: false }
              ]
            }
          ]
        },
        {
          id: '2',
          systemName: '多云管理平台',
          icon: 'mcp.svg',
          functions: [
            {
              id: '0',
              functionName: 'Dashboard',
              isGroup: false,
              icon: 'el-icon-s-home'
            },
            {
              id: '1',
              functionName: '资源中心',
              isGroup: true,
              icon: 'el-icon-s-platform',
              children: [
                {
                  id: '2',
                  functionName: '标签管理',
                  isGroup: false,
                  path: '/test'
                },
                {
                  id: '3',
                  functionName: '主机资源管理',
                  isGroup: false,
                  path: '/server'
                },
                {
                  id: '4',
                  functionName: '域名管理',
                  isGroup: false,
                  path: '/temp-test'
                },
                {
                  id: '5',
                  functionName: '云账户管理',
                  isGroup: false,
                  path: '/temp-test1'
                },
                { id: '6', functionName: '集群管理', isGroup: false }
              ]
            },
            {
              id: '7',
              functionName: '任务中心',
              isGroup: true,
              icon: 'el-icon-s-claim',
              children: [
                { id: '8', functionName: '模板管理', isGroup: false },
                { id: '9', functionName: '环境变量管理', isGroup: false },
                { id: '10', functionName: '任务计划', isGroup: false },
                { id: '11', functionName: '执行任务', isGroup: false }
              ]
            },
            {
              id: '12',
              functionName: '监控中心',
              isGroup: true,
              icon: 'el-icon-s-marketing',
              children: [
                { id: '13', functionName: '主机监控', isGroup: false },
                { id: '14', functionName: '告警管理', isGroup: false }
              ]
            }
          ]
        },
        {
          id: '3',
          systemName: '用户开发平台',
          icon: 'mp.svg',
          functions: [{ id: '1', functionName: '测试' }]
        },
        {
          id: '4',
          systemName: 'CRM',
          icon: 'crm.svg',
          functions: [
            {
              id: '31',
              functionName: 'Dashboard',
              isGroup: false,
              icon: 'el-icon-s-home'
            },
            {
              id: '32',
              functionName: '客户及商机管理',
              isGroup: true,
              icon: 'el-icon-user-solid',
              children: [
                {
                  id: '33',
                  functionName: '客户管理',
                  isGroup: false,
                  path: '/customer-management'
                },
                {
                  id: '35',
                  functionName: '客户分配',
                  isGroup: false,
                  path: '/customer-distribution'
                },
                {
                  id: '34',
                  functionName: '跟进记录',
                  isGroup: false,
                  path: '/customer-follow'
                },
                {
                  id: '36',
                  functionName: '合同管理',
                  isGroup: false,
                  path: '/contract/index'
                },
                {
                  id: '37',
                  functionName: '款项跟进',
                  isGroup: false,
                  path: '/history-payment/index'
                }
              ]
            },
            {
              id: 'e323cfde-8ee6-4c5c-bf83-f7da11337b4b',
              functionName: '客户接待',
              isGroup: true,
              icon: 'el-icon-s-promotion',
              children: [
                {
                  id: 'cb2ddf67-03cc-47a8-b04d-d90243760b8a',
                  functionName: '客户接待',
                  isGroup: false,
                  path: '/customer-reception'
                }
              ]
            },
            {
              id: '3fcddbee-299b-4eb1-911d-94e89d9a0219',
              functionName: '工作计划',
              isGroup: true,
              icon: 'el-icon-document',
              children: [
                {
                  id: '515a83fb-8d7d-4ba0-9392-7767f022cbdf',
                  functionName: '月度计划',
                  isGroup: false,
                  path: '/month-plan'
                },
                {
                  id: '38',
                  functionName: '周计划',
                  isGroup: false,
                  path: '/week-plan/index'
                }
              ]
            },
            {
              id: '81af697f-243e-48fe-a795-f91a6a287264',
              functionName: '售后服务',
              isGroup: true,
              icon: 'el-icon-phone-outline',
              children: [
                {
                  id: '764a99c4-808c-4c61-a4b5-69c0fc1a811b',
                  functionName: '售后回访',
                  isGroup: false,
                  path: '/after-sale-return-visit'
                },
                {
                  id: '7589b68c-1d5f-471c-b1fe-e2987de50f2d',
                  functionName: '物流签收',
                  isGroup: false,
                  path: '/logistics-receipt'
                }
              ]
            }
          ]
        }
      ],
      moduleData: null
    }
  },
  mutations: {
    setModules(state, modules) {
      state.moduleData = modules
    }
  }
}

export default { name: 'menu', store: menu }
