import Vue from 'vue'

// axios
import dAxios from './axios/index'
import dateFormat from './date-format/index'
import DrawerPlugin from './drawer/index'
import DDialog from './dialog/index'
import lowdb from './lowdb'
import queryParam from './query-param'
import date from './date'
import dateTime from './date-time'
import uuid from './uuid'

Vue.prototype.$axios = dAxios
Vue.prototype.$dateFormat = dateFormat
Vue.prototype.$drawer = DrawerPlugin
Vue.prototype.$dialog = DDialog
Vue.prototype.$lowdb = lowdb
Vue.prototype.$queryParam = queryParam
Vue.prototype.$date = date
Vue.prototype.$dateTime = dateTime
Vue.prototype.$uuid = uuid
