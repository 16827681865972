import Vue from 'vue'

const func = {
  bind: function (el, binding) {
    // 获取绑定在按钮上的权限点
    const actionCode = binding.value
    // 如果没有设置权限点编码
    if (!actionCode) {
      handleBtnDisabled(el, 'none action code', null)
      return
    }
    // funcId
    const { funcId } = Vue.prototype.$queryParam()
    // 获取当前用户的权限点集合
    const actionList = Vue.prototype.$lowdb('user').get('user.authorization.PERMISSION').value() || []
    const actionCodeList = actionList.filter(v => v.functionId === funcId).map(v => v.permissionCode)
    // 查看是否有权限访问当前按钮
    const isAccess = actionCodeList.includes(actionCode)
    if (isAccess) {
      return
    }
    handleBtnDisabled(el, 'no auth', actionCode)
  }
}

function handleBtnDisabled(el, msg, actionCode) {
  // 实现按钮禁用
  el.disabled = true
  el.classList.add('is-disabled')
  // 设置异常信息
  el.setAttribute('auth-exception-msg', msg)
  if (actionCode) {
    el.setAttribute('action-code', actionCode)
  }
}

export default func
