import Vue from 'vue'

/**
 * 获取用户功能授权接口
 */
export function loadFunctionAuthorizationService() {
  return Vue.prototype.$axios({
    method: 'GET',
    url: '/api/basic/erp-user/function-authorization'
  })
}

/**
 * 获取用户数据授权接口
 */
export function loadDataAuthorizationService() {
  return Vue.prototype.$axios({
    method: 'GET',
    url: '/api/basic/erp-user/data-authorization'
  })
}
