import axios from '@/plugins/axios'

function addErpUser(erpUser) {
  return axios({
    method: 'POST',
    url: '/api/basic/erp-user',
    data: erpUser
  })
}

function updateUser(erpUser) {
  return axios({
    method: 'PUT',
    url: '/api/basic/erp-user',
    data: erpUser
  })
}

function getUserById(id) {
  return axios({
    method: 'GET',
    url: `/api/basic/erp-user/${id}`
  })
}

function getOrgTree() {
  return axios({
    method: 'GET',
    url: '/api/basic/organization/common/tree'
  })
}

function getUserList(params) {
  return axios({
    method: 'GET',
    url: '/api/basic/erp-user/list',
    params
  })
}

function deleteUser(userId) {
  return axios({
    method: 'DELETE',
    url: `/api/basic/erp-user/${userId}`
  })
}

function getUser4Ref(hierarchyCode, chooseUser) {
  return axios({
    method: 'POST',
    url: `/api/basic/erp-user/user-ref?hierarchyCode=${hierarchyCode}`,
    data: chooseUser
  })
}

function getOrgUserStationTree() {
  return axios({
    method: 'GET',
    url: '/api/basic/erp-user/org-user-station-tree'
  })
}

/**
 * 加载当前用户到redis并返回
 * @param {*} accountId
 * @returns
 */
function loadCurrentErpUserInfo(accountId) {
  return axios({
    method: 'GET',
    url: `/api/basic/erp-user/load-to-redis/${accountId}`
  })
}

/**
 * 管理员修改密码
 * @param {*} pwd
 * @param {*} accountId
 * @returns
 */
function updatePwdByAdmin(sysPwdVO, accountId) {
  return axios({
    method: 'PATCH',
    url: `/api/uaa/account/pwd-by-admin/${accountId}`,
    data: sysPwdVO
  })
}

/**
 * 获取企业微信部门数据
 * @returns
 */
function getCorpDeptTree() {
  return axios({
    method: 'GET',
    url: '/api/basic/corp/dept-tree'
  })
}

/**
 * 获取企业微信部门下的人员信息
 * @param {*} deptId
 * @returns
 */
function getCorpUserList(deptId) {
  return axios({
    method: 'GET',
    url: `/api/basic/corp/user-list?departmentId=${deptId}`
  })
}

/**
 * 验证企业微信号是否重复绑定了用户账号
 * @returns
 */
function validateCorpRepeated(corpUserId, accountId) {
  return axios({
    method: 'GET',
    url: `/api/uaa/account/validate-corp-repeated?corpUserId=${corpUserId}${accountId ? '&accountId=' + accountId : ''}`
  })
}

/**
 * 重新绑定企业微信
 * @returns
 */
function rebindCorpWechat(accountId, corpUserId) {
  return axios({
    method: 'Patch',
    url: `/api/uaa/account/rebind-corp-webchat?accountId=${accountId}${corpUserId ? '&corpUserId=' + corpUserId : ''}`
  })
}

export {
  addErpUser,
  updateUser,
  getUserById,
  getOrgTree,
  getUserList,
  deleteUser,
  getUser4Ref,
  getOrgUserStationTree,
  loadCurrentErpUserInfo,
  updatePwdByAdmin,
  getCorpDeptTree,
  getCorpUserList,
  validateCorpRepeated,
  rebindCorpWechat
}
