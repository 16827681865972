import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../layout/main-page.vue'
import LoginPage from '../views/login/index-page.vue'
import bindHook from './hook.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main-page',
    component: MainPage,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () =>
          import(
            '../views/dashboard'
          )
      },
      // 客户管理
      {
        path: 'customer-management',
        name: 'customerManagement',
        component: () =>
          import('../views/customer-management/customer-management.vue')
      },
      // 客户分配
      {
        path: 'customer-distribution',
        name: 'customerDistribution',
        component: () =>
          import('../views/customer-distribution/customer-distribution.vue')
      },
      // 客户接待
      {
        path: 'customer-reception',
        name: 'customerReception',
        component: () =>
          import('../views/customer-reception/customer-reception.vue')
      },
      // 客户跟进
      {
        path: 'customer-follow',
        name: 'customerFollow',
        component: () => import('../views/customer-follow/customer-follow.vue')
      },
      // 合同管理
      {
        path: '/contract/index',
        name: 'contractIndex',
        component: () => import('../views/contract/index')
      },
      // 款项跟进
      {
        path: '/history-payment/index',
        name: 'HistoryPaymentIndex',
        component: () => import('../views/history-payment/index')
      },
      // 款项跟进
      {
        path: '/history-payment-no-contract/index',
        name: 'HistoryPaymentNoContractIndex',
        component: () => import('../views/history-payment-no-contract/index')
      },
      // 周计划
      {
        path: '/week-plan/index',
        name: 'WeekPlanIndex',
        component: () => import('../views/week-plan/index')
      },
      // 组织架构
      {
        path: 'organization-user-right/organization/index',
        name: 'OrganizationIndex',
        component: () =>
          import('../views/organization-user-right/organization/index')
      },
      // 角色管理
      {
        path: 'organization-user-right/role/index',
        name: 'RoleIndex',
        component: () =>
          import('../views/organization-user-right/role/index')
      },
      // 月度工作计划
      {
        path: 'month-plan',
        name: 'MonthPlan',
        component: () => import('../views/month-plan/MonthPlan.vue')
      },
      // 售后回访
      {
        path: 'after-sale-return-visit',
        name: 'AfterSaleReturnVisit',
        component: () =>
          import('../views/after-sale-return-visit/AfterSaleReturnVisit.vue')
      },
      // 物流签收
      {
        path: 'logistics-receipt',
        name: 'LogisticsReceipt',
        component: () =>
          import('../views/logistics-receipt/LogisticsReceipt.vue')
      },
      // 用户管理
      {
        path: 'organization-user-right/erp-user/index',
        name: 'erpUserIndex',
        component: () =>
          import('../views/organization-user-right/erp-user/index')
      },
      // 日报中心
      {
        path: 'daily-paper-center/index',
        name: 'dailyPaperCenter',
        component: () =>
          import('../views/daily-paper-center/index')
      },
      {
        path: 'work-attendance/index',
        name: 'work-attendance',
        component: () =>
          import('../views/work-attendance/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/exception/developing',
    name: 'developing',
    component: () => import('../views/exception/developing'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

bindHook(router)

export default router
