<template>
  <div>
    功能开发中😁
  </div>
</template>

<script>
export default {
  name: 'PhoneLogin',
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
