import low from 'lowdb'
import LocalStorage from 'lowdb/adapters/LocalStorage'

export default function (key, value = {}) {
  const adapter = new LocalStorage(key)
  const db = low(adapter)
  db.defaults(value)
    .write()
  return db
}
