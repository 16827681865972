<template>
  <div class="login-page-container">
    <div class="login-content-container">
      <div class="left-content">
        <div class="left-content-container">
          <div class="logo-content">
            <div class="product-logo-container">
              <div class="logo-image-wrapper">
                <img class="logo-image" :src="require('@/assets/yb-small-logo.png')">
              </div>
              <span class="logo-text">
                {{ this.$config.appearance.customCompanyName }}
              </span>
            </div>
            <div class="product-image">
              <img class="big-logo-image" :src="require('@/assets/big-logo.svg')">
            </div>
            <div class="contact-info">
              <div>
                <img class="contact-info-img" :src="require('@/assets/icons/phone.svg')">
                <span>{{ this.$config.appearance.contactMobilePhone }}</span>
              </div>
              <div>
                <img class="contact-info-img" :src="require('@/assets/icons/email.svg')">
                <span>{{ this.$config.appearance.contactMail }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="right-content-container">
          <div class="login-content">
            <div class="main-login-way-switch">
              <div class="login-by-account" :class="{ 'login-way-selected': loginByAccount }"
                @click="loginSwitch(true)">账号登录</div>
              <!-- <div class="login-by-phone" :class="{ 'login-way-selected': !loginByAccount }"
                @click="loginSwitch(false)">手机号登录</div> -->
            </div>
            <div class="login-form-content">
              <account-login v-if="loginByAccount"></account-login>
              <phone-login v-else></phone-login>
            </div>
          </div>
        </div>
        <div class="didu-rights">© {{ this.$config.appearance.developCompanyName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountLogin from './components/account-login.vue'
import PhoneLogin from './components/phone-login.vue'

export default {
  components: {
    AccountLogin,
    PhoneLogin
  },
  data() {
    return {
      loginByAccount: true
    }
  },
  methods: {
    loginSwitch(loginByAccount) {
      this.loginByAccount = loginByAccount
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.login-page-container {
  background-color: #fafafa;
  width: 100%;
  height: 100%;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-content-container {
    width: 1000px;
    height: 600px;
    background-color: #fff;
    box-shadow: 0 2px 30px 0 #5f6f7c17;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-content {
      width: 50%;

      .left-content-container {
        @extend .content-container;

        .logo-content {
          height: 500px;
          width: 400px;

          .product-logo-container {
            height: 60px;
            display: flex;
            align-items: center;

            .logo-image-wrapper {
              width: 42px;
              height: 42px;
              background-color: rgba(1,69,164,.72);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              .logo-image {
                width: 28px;
                height: 28px;
              }
            }

            .logo-text {
              margin-left: 15px;
              font-size: 32px;
              font-weight: 400;
              color: #494949;
              letter-spacing: 0.01em;
            }
          }

          .product-image {
            width: 100%;
            height: 360px;
            display: flex;
            align-items: center;

            .big-logo-image {
              width: 100%;
            }
          }

          .contact-info {
            width: 100%;
            margin-top: 30px;

            .contact-info-img {
              height: 16px;
              width: 16px;
              vertical-align: middle;
            }

            div:nth-child(1) {
              margin-bottom: 10px;
            }

            span {
              color: #aaa;
              font-size: 14px;
              margin-left: 10px;
              font-weight: 400;
            }
          }
        }
      }
    }

    .right-content {
      width: 50%;
      position: relative;

      &:before {
        content: "";
        border-left: 1px solid rgb(238 238 238);
        position: absolute;
        top: 36px;
        bottom: 36px;
      }

      .didu-rights {
        color: #aaa;
        font-size: 14px;
        position: absolute;
        bottom: 24px;
        width: 100%;
        text-align: center;
      }

      .right-content-container {
        @extend .content-container;
        padding-top: 20px;

        .login-content {
          height: 500px;
          width: 400px;

          .main-login-way-switch {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-size: 18px;
            font-weight: 500;
            color: #333;

            .login-by-account {
              margin-right: 30px;
              height: 28px;
            }

            .login-by-phone {
              height: 28px;
            }

            .login-by-account:hover,
            .login-by-phone:hover {
              cursor: pointer;
            }

            .login-way-selected {
              color: #53a8ff;
              border-bottom: 2px solid #53a8ff;
            }
          }

          .login-form-content {
            margin-top: 40px;
            height: 400px;
          }
        }
      }
    }
  }
}
</style>
