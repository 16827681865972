import axios from 'axios'
import Vue from 'vue'
import auth from '@/utils/auth.util'
// import { Message } from 'element-ui'
import router from '@/router/index'
import store from '@/store/index'

// 创建实例
const instance = axios.create({
  timeout: 30000
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // console.log(store.state.login.authInfo)
  const token = auth.getTokenFromCookie()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  // 在发送请求之前做些什么
  // funcId
  const headerFuncId = config.headers.funcId
  if (!headerFuncId) {
    const { funcId } = Vue.prototype.$queryParam()
    if (funcId) {
      config.headers.funcId = funcId
    }
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response
}, function (error) {
  switch (error.response.status) {
    case 401:
      dealWith401(error.response.data)
      break
    default:
      break
  }
  return Promise.reject(error)
})

function dealWith401() {
  // 清除本地身份信息
  store.dispatch('login/clearLocalAuth', null, { root: true })
  // 当前页面replace到相同页面，不catch的话router内部会报一个异常
  router.replace('/login').catch(() => {})
}

export default instance
