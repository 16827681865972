<template>
  <el-container class="top-layout">
    <el-header class="top-layout-header">
      <header-compo @changeApplication="changeApplication"></header-compo>
    </el-header>
    <el-container class="top-layout-container">
      <el-aside width="fit-content">
        <side-bar ref="functionRef"></side-bar>
      </el-aside>
      <el-main class="top-layout-main">
        <div class="top-layout-main-container">
          <div class="top-layout-main-bread-crumb">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for="item in breadcrumb" :key="item">{{ item }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="top-layout-main-content">
            <!--主要内容-->
            <router-view></router-view>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideBar from './components/side-bar'
import HeaderCompo from './components/header'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'MainPage',
  components: {
    SideBar,
    HeaderCompo
  },
  data() {
    return {}
  },
  computed: {
    // 从缓存取出面包屑
    ...mapState('authorization', {
      breadcrumb: state => state.breadcrumb
    })
  },
  methods: {
    ...mapActions('authorization', ['refreshSelectedFunction']),
    // 应用切换事件，调用功能组件，更新页面菜单为当前应用下的工作台
    changeApplication(application) {
      this.$refs.functionRef && this.$refs.functionRef.changeApplication(application)
    }
  }
}
</script>

<style lang="scss" scoped>
.top-layout {
  height: 100%;
  width: 100%;
  min-height: 600px;
  min-width: 1500px;
  .top-layout-header {
    // background-color: #53a8ff;
  }
  .top-layout-main {
    background-color: #f8f8f8;
    padding: 15px;
    .top-layout-main-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      .top-layout-main-bread-crumb {
        height: 36px;
        width: 100%;
        box-sizing: border-box;
        padding-top: 7px;
      }
      .top-layout-main-content {
        width: 100%;
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }
  .top-layout-container {
    overflow: hidden;
  }
}
</style>
