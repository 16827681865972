<template>
  <div class="menu-aside-container">
    <div class="menu-aside-menu-container">
      <el-menu class="el-menu-vertical-style" background-color="#ebf1f6" @open="handleOpen" @close="handleClose"
               :collapse="isCollapse" @select="(id) => selectFunction(id)" :default-active="selectedFunction && selectedFunction.id">
        <template v-for="func in functionTree">
          <el-menu-item v-if="func.functionType==='FUNCTION'" :index="func.id" :key="`item_${func.id}`">
            <i v-if="func.icon" :class="func.icon"></i>
            <span slot="title">{{ func.functionName }}</span>
          </el-menu-item>
          <el-submenu v-else :index="func.id" :key="`submenu_${func.id}`">
            <template slot="title">
              <i v-if="func.icon" :class="func.icon"></i>
              <span slot="title">{{ func.functionName }}</span>
            </template>
            <el-menu-item v-for="child in func.children" :key="child.id" :index="child.id">
              {{ child.functionName }}
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </div>
    <div class="menu-aside-toggle-container" @click="toggleFunction">
      <i v-if="isCollapse" class="el-icon-s-unfold toggle-icon-style"></i>
      <i v-else class="el-icon-s-fold toggle-icon-style"></i>
    </div>
  </div>
</template>

<script>

import { build } from '@/utils/tree.util'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SideBar',
  data() {
    return {
      isCollapse: false,
      functionTree: []
    }
  },
  computed: {
    ...mapGetters('authorization', ['functionList']),
    ...mapState('authorization', {
      selectedFunction: state => state.selectedFunction,
      selectedApplication: state => state.selectedApplication
    })
  },
  watch: {
    selectedApplication: {
      handler(newVal, oldVal) {
        this.commonInitFunctionTree()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.commonInitFunctionTree()
  },
  beforeMount() {
    // 验证是否通过链接进入，若有funcId，则更新功能地址
    const { funcId } = this.$queryParam()
    if (funcId) {
      this.refreshSelectedFunction(funcId)
      return
    }
    // 若没有funcId，则进入当前选中的应用
    this.changeApplication(this.selectedApplication)
  },
  methods: {
    ...mapActions('authorization', ['refreshSelectedFunction']),
    /**
     * 根据当前选中应用，更新菜单
     */
    commonInitFunctionTree() {
      const list = this.functionList.filter(v => v.applicationId === this.selectedApplication.id)
      this.functionTree = build(JSON.parse(JSON.stringify(list)))
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    toggleFunction() {
      this.isCollapse = !this.isCollapse
    },
    /**
     * 菜单点击事件
     * @param id 菜单id
     * @param isMenuActive 是否菜单点击触发 // 刷新页面、链接进入页面都不算菜单点击行为，不会触发 $router.push 事件
     */
    selectFunction(id) {
      const selectedFunction = this.functionList.find(v => v.id === id)
      if (selectedFunction) {
        const toPath = selectedFunction.functionUrl + '?funcId=' + id || null
        const currPath = window.location.pathname + window.location.search
        if (toPath === currPath) {
          return
        }
        this.refreshSelectedFunction(id)
        this.$router.push(toPath)
      }
    },
    /**
     * 应用切换过后，更新菜单信息
     * @param application
     */
    changeApplication(application) {
      const list = this.functionList.filter(v => v.applicationId === application.id)
      this.selectFunction(list[0].id)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-vertical-style:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  height: 100%;
  background-color: #ebf1f6;
  border: none;
}

.menu-aside-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .menu-aside-menu-container {
    flex-grow: 1;
    overflow-y: auto;
    flex-basis: 0;
  }
  .menu-aside-toggle-container {
    height: 30px;
    background-color: #21b568cc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .toggle-icon-style {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
  }
  .menu-aside-toggle-container:hover {
    background-color: #21b568
  }
}

.item-a-style {
  height: 56px;
  line-height: 56px;
  font-size: 14px;
  color: #303133;
  padding: 0 20px;
  list-style: none;
  position: relative;
  transition: border-color .3s, background-color .3s, color .3s;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none;
}
</style>
