import Vue from 'vue'
import store from '@/store'

const DialogPlugin = function (EmbeddedComponent, options) {
  const dialogDefaultAttributes = {
    title: null,
    closeOnClickModal: false
  }
  return new Promise((resolve, reject) => {
    const { payload, beforeClose } = options
    delete options.payload
    delete options.beforeClose
    const dialogAttributes = Object.assign({}, dialogDefaultAttributes, options)
    // eslint-disable-next-line no-new
    const component = new Vue({
      name: 'DialogPluginComponent',
      store,
      components: { EmbeddedComponent },
      data() {
        return {
          visible: false
        }
      },
      methods: {
        submit(data) {
          resolve(data)
          this.close()
        },
        cancel(data) {
          this.close(data)
        },
        close(data) {
          if (beforeClose && typeof beforeClose === 'function') {
            beforeClose(data)
          }
          // 保证点击x以及关闭按钮都能触发then回调
          // resolve(data)
          this.visible = false
        }
      },
      mounted() {
        this.visible = true
      },
      render() {
        const _this = this
        const {
          submit,
          cancel
        } = _this
        const embeddedComponentAttrs = {
          style: {},
          class: [],
          props: {
            ...payload
          },
          on: {
            submit,
            cancel
          }
        }

        dialogAttributes.beforeClose = (done) => cancel()

        const finalDialogAttributes = {
          attrs: dialogAttributes
        }

        return (
          <el-dialog ref="dialogRef" {...finalDialogAttributes} visible={this.visible}>
            <EmbeddedComponent {...embeddedComponentAttrs}/>
          </el-dialog>
        )
      },
      beforeDestroy() {
        this.$el.remove()
      }
    })
    const vm = component.$mount()
    document.body.appendChild(vm.$el)
  })
}

export default DialogPlugin
