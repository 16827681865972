import Vue from 'vue'

/**
 * 获取日期
 */
export function getDateService(pattern = 'yyyy-MM-dd') {
  return Vue.prototype.$axios({
    method: 'GET',
    url: '/api/basic/util/date',
    params: {
      pattern
    }
  })
}

/**
 * 获取日期时间
 */
export function getDateTimeService(pattern = 'yyyy-MM-dd HH:mm:ss') {
  return Vue.prototype.$axios({
    method: 'GET',
    url: '/api/basic/util/date-time',
    params: {
      pattern
    }
  })
}

/**
 * 获取一个UUID
 */
export function getUuidService(count = 1) {
  return Vue.prototype.$axios({
    method: 'GET',
    url: '/api/basic/util/uuid',
    params: {
      count
    }
  })
}
