import axios from '@/plugins/axios'
import qs from 'qs'

function passwordLogin(loginInfo) {
  loginInfo.client_id = process.env.VUE_APP_CLIENT_ID
  loginInfo.client_secret = process.env.VUE_APP_CLIENT_SECRET
  return axios({
    method: 'POST',
    url: '/oauth/token?grant_type=password&scope=all',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(loginInfo)
    // auth: {
    //   username: process.env.VUE_APP_CLIENT_ID,
    //   password: process.env.VUE_APP_CLIENT_SECRET
    // }
  })
}

function userLogout() {
  return axios({
    method: 'POST',
    url: '/api/uaa/auth/logout'
  })
}

export {
  passwordLogin,
  userLogout
}
