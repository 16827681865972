// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import Vue from 'vue'

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  '.',
  // Do not look in subdirectories
  true,
  // Only include "_base-" prefixed .vue files
  /index\.vue$/
)

// For each matching file name...
requireComponent.keys().forEach((filePath) => {
  // Get the component config
  const componentConfig = requireComponent(filePath)
  // Get the PascalCase version of the component name
  const filePathArray = filePath.split('/')
  const componentName = filePathArray[filePathArray.length - 2]
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig)
})
