<template>
  <div class="header-compo-container">
    <div class="header-left-content">
      <div class="platform-title">
        <img style="height: 44.2px;width: 153px;" :src="require('@/assets/yb-logo.png')">
        <!-- <span>{{ this.$config.appearance.companyName }}</span> -->
      </div>
      <div class="platform-system-menu">
        <el-dropdown trigger="click" @command="changeApplication">
          <span class="el-dropdown-link">
            {{ selectedApplication.appName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in applicationList" :command="item" :key="item.id">
              <div class="platform-system-menu-item">
                <img :src="require(`@/assets/icons/system/${item.appIcon}`)" />
                <span>{{ item.appName }}</span>
                <img v-show="selectedApplication.id === item.id" class="platform-system-menu-item-choosen"
                  :src="require('@/assets/icons/system/choosen.svg')" />
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <d-websocket sessionType="DASHBOARD" @onmessage="consumerMsg"></d-websocket>
    <div class="platform-tools-container">
      <!-- <div class="header-icon-area"> -->
        <!-- <el-badge :value="11">
          <i class="el-icon-chat-line-round header-icon-style"></i>
        </el-badge> -->
      <!-- </div> -->
      <el-popover
        placement="bottom"
        width="150">
        <div class="toolbar-drop-panel">
          <el-button class="toolbar-drop-panel-btn" size="mini" plain @click="userLogout">退出登录</el-button>
        </div>
        <div slot="reference" class="header-user-info">
          <el-avatar shape="square" :size="25" :src="require('@/assets/icons/default_avatar.png')">
          </el-avatar>
          <span class="header-user-info-name">你好，{{ userInfo.userRealName }}</span>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'HeaderCompo',
  data() {
    return {}
  },
  computed: {
    // 用户信息
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('authorization', ['applicationList']),
    ...mapState('authorization', {
      selectedApplication: state => state.selectedApplication
    })
  },
  props: {
  },
  beforeMount() {
    // 页面加载时，如果未选中应用，则默认选中第一个应用
    if (!this.selectedApplication) {
      this.changeApplication(this.applicationList[0])
    }
  },
  methods: {
    ...mapActions('login', ['logout']),
    ...mapActions('authorization', ['refreshSelectedApplication']),
    /**
     * 应用切换事件，切换应用后，调用功能组件的函数，更新页面
     * @param application
     */
    changeApplication(application) {
      this.refreshSelectedApplication(application.id)
      this.$emit('changeApplication', application)
    },
    consumerMsg(msg) {
      this.$notify({
        title: '消息提示',
        message: msg.data,
        duration: 0
      })
    },
    userLogout() {
      // 清空本地缓存（cookie,store）
      // 请求后端退出登录
      this.logout().then(() => {
        this.$router.replace({ path: '/login' })
      }).catch(() => {
        this.$message.error('退出登录异常，请联系管理员！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-compo-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left-content {
  display: flex;
  align-items: center;

  .platform-title {
    color: #fff;
    font-weight: 800;
    font-size: 30px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
  }

  .platform-system-menu {
    display: flex;
    align-items: center;
    margin-left: 18px;

    span {
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      padding: 8px 12px;
      border-radius: 4px;
    }

    &:before {
      content: "";
      padding-right: 6px;
      border-left: 1px solid #fff;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
    }

    span:hover {
      cursor: pointer;
      background-color: #a0cfff;
    }
  }
}

.platform-system-menu-item {
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
    min-width: 130px;
    max-width: 180px;
  }
}

.platform-tools-container {
  width: 220px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .header-user-info {
    display: flex;
    align-items: center;
    height: 36px;
    cursor: pointer;
    border-radius: 4px;

    span.header-user-info-name {
      color: #fff;
      font-size: 12px;
      margin-left: 10px;
      margin-right: 10px;
    }

    span.el-avatar {
      margin-left: 10px;
    }
  }

  .header-icon-area {
    height: 36px;
    width: 36px;
    // background-color: antiquewhite;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;

    .header-icon-style {
      color: #fff;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .header-icon-area:hover,
  .header-user-info:hover {
    background-color: #a0cfff;
  }
}

.toolbar-drop-panel {
  .toolbar-drop-panel-btn {
    width: 100%;
  }
}
</style>>
