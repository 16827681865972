export function build(data = [], props = {
  id: 'id',
  parentId: 'parentId',
  children: 'children'
}) {
  const obj = {}
  data.forEach((item) => {
    obj[item[props.id]] = item
  })
  const parentList = []
  data.forEach((item) => {
    const parent = obj[item[props.parentId]]
    if (parent) {
      // * 当前项有父节点
      parent[props.children] = parent[props.children] || []
      parent[props.children].push(item)
    } else {
      // * 当前项没有父节点 -> 顶层
      parentList.push(item)
    }
  })
  return parentList
}
