import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import auth from '@/utils/auth.util'

// 取消转圈显示，只保留进度条
NProgress.configure({ showSpinner: false })

const bindHook = (_router) => {
  _router.beforeEach((to, from, next) => {
    NProgress.start()
    // 判断是否存在令牌
    if (to.matched.some(r => {
      return r.meta.requiresAuth !== false
    })) {
      const token = auth.getTokenFromCookie()
      if (!token) {
        next({
          name: 'login',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    } else {
      next()
    }
  })

  _router.afterEach((to, from) => {
    NProgress.done()
  })
}

export default bindHook
