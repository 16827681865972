import Vue from 'vue'
import store from '@/store'

/**
 * usage:
 * 1. const drawerAttributes = { title: '新增顶级组织',... }
 * 2. const embeddedComponentAttributes = { formData: [],... } // 等同于打开的组件的 props
 * 3. this.$drawer(VueComponent, Object.assign({}, drawerAttributes, { payload: embeddedComponentAttributes })) .then((res) => { console.log(res) })
 * okCallback：
 *   this.$emit('submit', {})
 * closeCallback:
 *   this.$emit('cancel')
 * @param EmbeddedComponent 待嵌入的组件
 * @param options 抽屉参数
 * @returns {Promise<unknown>}
 * @constructor
 */
const DrawerPlugin = function (EmbeddedComponent, options) {
  const drawerDefaultAttributes = {
    title: null,
    showClose: true,
    wrapperClosable: false
  }
  return new Promise((resolve, reject) => {
    const { payload, beforeClose } = options
    delete options.payload
    delete options.beforeClose
    const drawerAttributes = Object.assign({}, drawerDefaultAttributes, options)
    // eslint-disable-next-line no-new
    const component = new Vue({
      name: 'DrawerPluginComponent',
      store,
      components: { EmbeddedComponent },
      data() {
        return {
          visible: false
        }
      },
      methods: {
        submit(data) {
          resolve(data)
          this.close()
        },
        cancel() {
          this.close()
        },
        close() {
          if (beforeClose && typeof beforeClose === 'function') {
            beforeClose()
          }
          this.visible = false
        }
      },
      mounted() {
        this.visible = true
      },
      render() {
        const _this = this
        const {
          submit,
          cancel
        } = _this
        const embeddedComponentAttrs = {
          style: {},
          class: [],
          props: {
            ...payload
          },
          on: {
            submit,
            cancel
          }
        }

        drawerAttributes.beforeClose = (done) => cancel()
        const finalDrawerAttributes = {
          attrs: drawerAttributes
        }
        return (
          <el-drawer ref="drawerRef" {...finalDrawerAttributes} visible={this.visible}>
            <EmbeddedComponent {...embeddedComponentAttrs}/>
          </el-drawer>
        )
      },
      beforeDestroy() {
        this.$el.remove()
      }
    })
    const vm = component.$mount()
    document.body.appendChild(vm.$el)
  })
}

export default DrawerPlugin
