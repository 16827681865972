<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'app',
  methods: {
    ...mapActions('authorization', ['resetAuthorization']),
    ...mapActions('user', ['resetUserInfo'])
  },
  beforeMount() {
    // 刷新页面后，重新加载系统、菜单、权限点
    this.resetAuthorization()
    // 刷新页面后，重新加载用户信息
    this.resetUserInfo()
  },
  mounted() {
    document.body.className = 'theme-yb'
  }
}

</script>

<style lang="scss">
@import "assets/css/ele-global.scss";
@import "assets/css/theme/register.scss";

%style-init {
  margin: 0px;
  height: 100%;
}

html,
body {
  @extend %style-init;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;

  #app {
    @extend %style-init;
  }
}

#nprogress .bar {
      background: #21b568cc !important;
    }
</style>
