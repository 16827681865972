import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// Globally register all `_base`-prefixed components
import '@/packages/global-register'
import '@/directives/global-register'

// Globally register plugins
import '@/plugins/index'

import basicConfig from '@/config/system-basic-config'

Vue.prototype.$config = basicConfig

Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
