<template>
  <div class="d-panel-item">
    <div class="d-panel-item-title">
      {{ title }}
    </div>
    <div class="d-panel-item-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'd-panel-item',
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.d-panel-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .d-panel-item-title {
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 5px;
    font-size: 15px;
  }
}
</style>
